.testimonials-section {
  padding: 120px 100px;

  .title {
    font-family: "Oswald", sans-serif;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 120px;
    color: #20208e;
    margin-bottom: 100px;
  }

  .content {
    display: flex;
    font-family: "Josefin Sans";
    flex-wrap: wrap;

    .item {
      flex: 0 calc(50% - 2rem);
      margin-bottom: 2rem;
    }

    .item:nth-child(1n){
      margin-right: 1rem;
    }

    .item:nth-child(2n){
      margin-left: 1rem;
    }

    .text {
      background: #EBEBEB;
      border-radius: 8px;
      padding: 2.5rem;
      font-size: 16px;
      text-align: left;
      color: #111158;

      p {
        margin: .5rem 0 0;
      }
    }

    .affiliate {
      display: inline-block;
      color: #20208e;
      margin-top: .5rem;
    }
  }
}

@media (max-width: 991px) {
  .testimonials-section {
    padding: 60px 30px;

    .title {
      font-size: 58px;
      text-align: center;
      margin-bottom: 60px;
    }

    .content {
      flex-direction: column;

      .item:nth-child(1){
        margin-right: 0;
        margin-bottom: 2rem;
      }
  
      .item:nth-child(2){
        margin-left: 0;
        margin-bottom: 2rem;
      }
    }
  }
}