@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');

button {
  outline: 0 !important;
}
a {
  outline: 0 !important;
}

// body {
//   overflow: hidden;
// }